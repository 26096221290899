:root {
  --color-white: white;
  --color-darkBlue: #0a1128;
  --color-purple: #639;
  --color-white: white;
  --color-gold: #c19403;
  --color-goldOpa: #c19503d2;
}

.image-checkbox img:hover {
  opacity: 1;
}
.image-checkbox-label:hover + .image-checkbox img {
  opacity: 1;
}
.zLogo {
  -webkit-filter: drop-shadow(0px 0px 3px #fff);
  filter: drop-shadow(0px 0px 3px #fff);
}
.menuLabel {
  line-height: 20px;
  top: -22px;
  position: relative;
  font-size: 9pt !important;
  text-align: center;
}
.userInfo {
  z-index: 1060;
  width: auto;
  height: 80px;
  padding: 10px 0 !important;
  right: 5px !important;
  position: absolute;
}
.shadow-hard {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.89) !important;
}
.middle-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bannerL-1,
.bannerL-2 {
  padding-right: 115px;
  padding-left: 10px;
}
.bannerL-1 img {
  max-height: 250px !important;
}
.bannerL-2 img {
  max-height: 150px !important;
}
.bannerR-1 img {
  max-height: 150px !important;
}
.bannerR-2 img {
  max-height: 80px !important;
}
section {
  padding: 0 !important;
}
.App {
  text-align: center;
}

.bg1 {
  background-image: url("https://old.artisle-online.com/assets/banner-left.jpg");
  background-size: cover;
  background-position-x: right;
  width: 100%;
}
.bg2 {
  background-image: url("https://old.artisle-online.com/assets/banner-right.jpg");
  background-size: cover;
  background-position-x: left;
  width: 100%;
}
.bg3 {
  background-image: url("https://old.artisle-online.com/assets/pencils.jpg");
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
  background-color: #f7f7f7;
  min-height: 300px;
  width: 100%;
  position: relative;
}
.bg4 {
  background-image: url("https://old.artisle-online.com/assets/art-girl.jpg");
  background-size: contain;
  background-position-x: center;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 20vw;
  width: 100%;
  position: relative;
}

.customDropdown.active {
  display: block;
}
.customDropdown {
  display: none;
  position: absolute;
  background-color: var(--color-darkBlue);
  width: 240px;
  padding: 0;
}
.customDropdown li {
  color: white !important;
  border-bottom: 0.5px #3d4251 solid;
  padding: 0 1rem;
}
.customDropdown .navLink.active li {
  color: #3f3f3f !important;
}
.customDropdown .navLink.active li:hover {
  background-color: var(--color-darkBlue) !important;
}
.customDropdown a:last-child li {
  border-bottom: none;
}
.customDropdown li:hover {
  background-color: var(--color-white) !important;
}
.customDropdown li:hover {
  color: var(--color-darkBlue) !important;
}
.navItems {
  position: absolute;

  z-index: 1060;
  bottom: 0;
}
.userMenu {
  width: auto;
  margin: 0 auto;
  padding-right: 225px !important;
  z-index: 1060;
  bottom: 0;
}
.text-gold {
  color: var(--color-gold);
}
.navList {
  z-index: 1000;
  display: inline-flex;
  width: auto;
}

.App-link {
  color: #61dafb;
}

.App-container {
  padding: 0;
  padding-top: 80px !important;
  z-index: 100;
}
.footer {
  z-index: 500;
  background-color: #eee;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.navLink {
  text-transform: uppercase !important;
  color: #fff;
  text-decoration: none !important;
  cursor: pointer;
}

.navLink.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.navLink li {
  display: block;
  height: 77px;
  line-height: 77px;
}
.navLink:hover {
  color: #d4af37 !important;
  font-size: 1.3rem;
  text-decoration: none;
}
.navLink.active {
  color: #ffffff !important;
  font-size: 1.3rem;
  border-bottom: 3px solid #ffffff;
}
.navbar-toggler {
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 1060;

  font-size: 2rem;
  overflow: hidden;
  width: 50px;
}
.navbar-toggler.active {
  position: fixed;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.closeMenu {
  display: inline !important;
  margin-top: 0;
}
.closeMenu.active {
  border: none !important;
  opacity: 1;
}
.openMenu {
  display: inline !important;
  transition: margin 0.4s ease-in-out;
  margin-right: 22px;
}
.openMenu.active {
  transition: margin 0.4s ease-in-out;
  margin-right: -80px;
}
.btn {
  text-transform: uppercase !important;
  border-radius: 0 !important;
  border-width: 2px;
  box-shadow: none !important;
}
.btn-outline-white {
  color: var(--color-white);
  border-color: var(--color-white);
}
.btn-outline-white:hover {
  color: var(--color-purple);
  background-color: var(--color-white) !important;
  border-color: var(--color-white);
}

.btn-purple {
  color: var(--color-white);
  border-color: var(--color-purple);
  background-color: var(--color-purple);
}
.btn-purple:hover {
  color: var(--color-white);
  border-color: #54417b;
  background-color: #41166c;
}
.btn-outline-purple {
  color: var(--color-purple);
  border-color: var(--color-purple);
  background-color: var(--color-white);
}
.btn-outline-purple:hover {
  color: var(--color-white);
  border-color: var(--color-purple);
  background-color: var(--color-purple);
}
.btn-gold {
  color: var(--color-white);
  border-color: var(--color-gold);
  background-color: var(--color-gold);
}
.btn-gold:hover,
.btn-outline-gold:hover {
  color: var(--color-white);
  border-color: #917005;
  background-color: #917005;
}
.btn-outline-gold {
  color: var(--color-gold);
  border-color: var(--color-gold);
  background-color: var(--color-white);
}
.form-control:focus {
  border-color: var(--color-purple);
  box-shadow: 0 0 0.25rem 0.1rem var(--color-purple);
}
.scrolling-wrapper {
  overflow-x: auto;
}

.topArtistscard {
  border-color: var(--color-gold);
}
.topArtistscardImage {
  width: 100%;
  height: 180px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0.25rem 0.25rem 0 0;
}
.topArtistscardImage img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
}

.registeStepindicator {
  max-width: 160px;
  transition: ease-in 0.5s;
  -webkit-transition: ease-in 0.5s;
  -moz-transition: ease-in 0.5s;
  -o-transition: ease-in 0.5s;
  border-bottom: 2px solid #16171b;
}
.registeStepindicator.active {
  max-width: 200px;
  font-size: 17pt;
  border: 2px solid #16171b;
}
.image-check,
.image-checkbox {
  padding: 0.4rem;
  position: relative;
  display: flex;
  cursor: pointer;
}
.image-check img,
.image-checkbox img {
  transition: opacity 0.3s ease-in;
  opacity: 0.4;
}
.image-check-label,
.image-checkbox-label {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.4rem;
  text-transform: uppercase !important;
  font-weight: bold;
  transition: top 1s ease, transform 1s ease, background-color 1s ease;
}
.image-checkbox-label {
  width: auto !important;
  left: 0.4rem;
  right: 0.4rem;
}
.btn-check:checked + .image-check img,
.btn-check:checked + .image-checkbox img {
  opacity: 1;
}
.btn-check:checked + .image-check .image-check-label,
.btn-check:checked + .image-checkbox .image-checkbox-label {
  color: white;
  background-color: var(--color-gold);
  margin-bottom: 0.4rem;
  transform: translateY(-150%);
  top: 100%;
}
.btn-check:checked + .image-checkbox .image-checkbox-label {
  background-color: var(--color-goldOpa);
}
.profile-banner {
  background: antiquewhite;
  background-image: url("https://old.artisle-online.com/assets/art-girl.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
}
.profile-avatar {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin-top: -100px;
  background-image: url("https://old.artisle-online.com/assets/pencils.jpg");
  background-size: cover;
  background-position: center;
}

@media (max-width: 991.98px) {
  .bg3 {
    background-position-x: center;
  }

  .userMenu {
    position: fixed;
    padding: 0 1.5rem !important;
    bottom: 0;
    background-color: rgb(45, 45, 45);
    right: 0;
  }
  .profile-banner {
    height: 252.7px !important;
  }
}
@keyframes floatInPost {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes floatOutPost {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .bg4 {
    height: 200px;
  }
  .navItems.active {
    position: fixed;
    right: 0;
    left: 0;
    top: 0px;
    padding: 50px 0 0 !important;
    display: grid !important;
    grid-auto-rows: min-content;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    animation-name: floatIn;
    animation-duration: 0.6s;
  }

  @keyframes floatIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  .author {
    position: relative !important;
  }
  .customDropdown {
    position: relative;

    width: 100%;
  }
  .navLink li {
    height: auto;
  }
  .registeStepindicator {
    font-size: 9pt;
  }
  .registeStepindicator.active {
    font-size: 12pt;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .image-checkbox-label {
    font-size: 9pt;
  }
  .profile-banner {
    height: 204.5px !important;
  }
  .profile-avatar {
    height: 150px;
    width: 150px;
    margin-top: -75px;
  }
}

@media (max-width: 575.98px) {
  .profile-banner {
    height: calc(100vw * 0.378) !important;
  }
}
